import React from "react";
import Ample from "../components/ample/ample";
import {useSiteMetadata} from "../branding";

const Mailto = ({ email, subject = '', body = '', children }) => {
  let params = subject || body ? '?' : '';
  if (subject) params += `subject=${encodeURIComponent(subject)}`;
  if (body) params += `${subject ? '&' : ''}body=${encodeURIComponent(body)}`;

  return <a href={`mailto:${email}${params}`}>{children}</a>;
};

const ContactUsPage = (props) => {
  const siteMetadata = useSiteMetadata();
  
  return (
      <Ample currPageTitle="Contact Us" seoDescription="Contact Us" location={props.location.href} >
        <div className="white-box">
          Help us make this site better.  Send any feedback, suggestions, or ideas to:&nbsp;
          <Mailto email="tradingsolutions@harsip.com" subject={`${siteMetadata.title} feedback`} >
            tradingsolutions@harsip.com
          </Mailto>
        </div>
      </Ample>);
  
};

export default ContactUsPage;